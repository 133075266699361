<template>
  <div class="px-4 md:px-24 grid grid-flow-row">
    <h1
      class="
        text-StemBlue
        place-self-start
        font-semibold
        text-normal
        pr-4
        leading-loose
      "
    >
      Aktivitetet:
    </h1>
    <select
      name="Aktivitetet"
      class="
        float-left
        cursor-pointer
        w-max
        lg:w-72
        border-2 border-StemYellow
        px-2
        bg-transparent
        StemTextSecond
        text-lg
        font-normal
        h-14
      "
      v-model="selectedCategory"
    >
      <!-- <option value="0" selected>All</option> -->
      <option
        v-for="item in activitis"
        :key="`age_${item.id}`"
        :value="item.id"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
  <div class="static px-4 md:px-24 pt-20 AboutDeco4">
    <div class="flex flex-col md:flex-row md:justify-between">
      <div class="md:relative place-items-start">
        <ideaLabCard class="pt-5" v-for="i in filteredItems" :key="i" :data="i">
        </ideaLabCard>
      </div>
      <div class="sticky w-max md:pl-10 pt-10">
        <div
          class="
            border-StemPurple border-l-2
            grid grid-flow-row
            md:grid-flow-col
          "
        >
          <div class="md:w-60">
            <h1 class="text-StemBlue font-bold text-4xl pl-4 text-left">
              Regjistrohu
            </h1>
          </div>
        </div>
        <div class="pt-10">
          <div class="">
            <label
              for="name"
              class="block my-2 text-sm font-semibold StemTextSecond"
              >Emri dhe mbiemri *</label
            >
            <input
              type="text"
              name="name"
              placeholder="Emri dhe mbiemri"
              v-model="name"
              required
              class="
                w-full
                px-3
                py-2
                placeholder-gray-300
                border-2 border-StemYellow
                focus:outline-none
                focus:ring
                focus:ring-StemYellow
                focus:border-StemYellow
              "
            />
          </div>
          <div class="">
            <label
              for="email"
              class="block my-2 text-sm font-semibold StemTextSecond"
              >E-mail adresa *</label
            >
            <input
              type="email"
              name="email"
              placeholder="you@email.com"
              v-model="email"
              required
              class="
                w-full
                px-3
                py-2
                placeholder-gray-300
                border-2 border-StemYellow
                focus:outline-none
                focus:ring
                focus:ring-StemYellow
                focus:border-StemYellow
              "
            />
          </div>
          <div class="">
            <label
              for="address"
              class="block my-2 text-sm font-semibold StemTextSecond"
              >Adresa *</label
            >
            <input
              type="text"
              name="address"
              placeholder="Rr. Ilinden p.n., Tetove"
              v-model="address"
              required
              class="
                w-full
                px-3
                py-2
                placeholder-gray-300
                border-2 border-StemYellow
                focus:outline-none
                focus:ring
                focus:ring-StemYellow
                focus:border-StemYellow
              "
            />
          </div>
          <div class="">
            <label
              for="phone"
              class="block my-2 text-sm font-semibold StemTextSecond"
              >Numri i kontaktit *</label
            >
            <input
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
              name="phone"
              placeholder="070123123"
              v-model="phone"
              required
              class="
                w-full
                px-3
                py-2
                placeholder-gray-300
                border-2 border-StemYellow
                focus:outline-none
                focus:ring
                focus:ring-StemYellow
                focus:border-StemYellow
              "
            />
          </div>
          <div>
            <label
              for="phone"
              class="block mt-2 text-sm font-semibold StemTextSecond"
              >Zgjedh kursin *</label
            >
            <select
              v-model="course"
              class="
                bg-white
                mt-3
                class=
                cursor-pointer
                block
                mb-2
                w-full
                border-2 border-StemYellow
                bg-transparent
                StemTextSecond
                text-lg
                font-normal
                h-14
              "
            >
              <option v-for="shit in items" :key="shit" :data="shit">
                {{ shit.title }}
              </option>
            </select>
          </div>
          <div class="flex justify-center gap-5 pt-5">
            <div class="pt-3">
              <input type="checkbox" id="checkbox" v-model="checked" required />
              <label for="checkbox" class="pl-2"> Konfirmo</label>
            </div>
            <div class="">
              <button
                :disabled="!checked"
                @click="sendEmail"
                :class="{
                  'bg-StemYellow text-white hover:text-StemYellow hover:border-StemYellow hover:bg-transparent':
                    checked,
                  'bg-yellow-100 text-white ': !checked,
                }"
                class="
                  rounded-lg
                  border
                  text-base
                  font-normal
                  shadow-2xl
                  px-6
                  py-3
                "
              >
                Regjistrohu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ideaLabCard from "../base/ideaLabCard.vue";
import idealabs from "@/data/idealabs";
import activitis from "@/data/idealabCategories";
import { mapActions, mapState } from "vuex";
import { sendEmailService } from "../../services/email";
import { IDEALAB } from "../../constants/constants";

export default {
  components: { ideaLabCard },
  data() {
    return {
      items: idealabs.data,
      name: "",
      email: "",
      address: "",
      phone: "",
      course: null,
      checked: false,
      activitis: activitis.data,
      selectedCategory: 0,
    };
  },
  methods: {
    ...mapActions(["changeButtonState"]),
    async sendEmail() {
      if (!this.checked) {
        // TODO: fix UI to display checked as requiered
        return;
      }

      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
        course: this.course,
      };

      const res = await sendEmailService(IDEALAB, data);
      if (!res) {
        console.log("Mail not sent");
      }

      // Reset form field
      this.name = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.course = null;
    },
  },
  computed: {
    ...mapState({
      buttonState: (state) => state.buttonModule.ideaLabShow,
    }),
    filteredItems() {
      return this.items.filter(
        (i) =>
          +i.activityId === +this.selectedCategory ||
          +this.selectedCategory === 0
      );
    },
  },
};
</script>

<style>
.AboutDeco3 {
  background-image: url("./../../assets/Svg/AboutDeco3.svg");
  background-repeat: no-repeat;
  background-position-x: 10%;
  background-position-y: 50%;
}
.AboutDeco4 {
  background-image: url("./../../assets/Svg/AboutDeco4.svg");
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  z-index: -1;
}
</style>
import STEMBasic3 from "@/assets/images/courses/STEMBasic3.jpg";

const data = [
    {
        id: 0,
        age:"5",
        activityId: 2,
        image: STEMBasic3,
        title: "STEM.org Certified Master Trainer Program™",
        loaction: "Online dhe STEMLab",
        date:"4 Prill 2022",
        time:"15 orë",
        price: "150 USD",
        priorKnolage:"Nuk duhet",
        benefits:["Çertifikatë digjiitale","STEM ID# që verifikohet dhe shpërndahet në internet","1000 faqe resurse me qasje gjatë gjithë jetës","Mundësi shtesë për çertifikata për nxënësit"],
        description:`STEM edukimi në nivel global është shndërruar në një mekanizëm të fuqishëm transformimi të procesit arsimor. Duke ju referuar rezultateve dhe impaktit shoqëror që ka pasur STEM-i në shumë shtete, me Qendrën për Edukim dhe Mësim Inovativ – STEMLab ka nisur iniciativën për trajnimin e stafit të shkollave fillore dhe të mesme për STEM edukim. STEMLab në partneritet me STEM.org – Amerikë, do të ofroj programin e tyre të çertifikuar - STEM.org Certified Master Trainer Program™ (ndjekur nga më shumë se 15000 eduaktorë nga më shumë se 80 shtete, me mbi 1000 faqe resurse me qasje gjatë gjithë jetës).`,
        text1:"Programi do të realizohet në katër faza:",
        text2:["2 orë - Hapja solemne dhe prezantim i përmbajtjes","10 orë - Trajnimi bazë për STEM","2 orë - STEM puntoria","1 orë - Ndarja e çertifikatave dhe ërmbyllja solemne e programit"],
    },  
];

export default { data};
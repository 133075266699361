<template>
<div class="shadow-xl rounded-xl w-min">


<div class="grid grid-flow-row">
<div class="">
<div
    class="
     
      place-content-start
      
      grid grid-flow-row
      md:grid-flow-col
      gap-5
      mx-5
    "
  >
      
      <div class="w-40 xl:w-max mt-2">
        <h1 class="text-StemBlue font-semibold text-sm xl:text-base ">Titulli i aktivitetit</h1>
        <h1 class="text-StemTextSecond font-bold text-sm xl:text-base my-5">{{ data.title }}</h1>
      </div>
      <div class="w-20 xl:w-max mt-2">
        <h1 class="text-StemBlue font-semibold text-sm xl:text-base">Lokacioni</h1>
        <div class="text-StemTextSecond text-sm xltext-base my-5">
          {{ data.loaction }}
        </div>
      </div>
      <div class="lg:w-max mt-2">
        <h1 class="text-StemBlue font-semibold text-sm xl:text-base">Fillimi</h1>
        <div class="text-StemTextSecond text-sm xltext-base my-5">{{ data.date }}</div>
      </div>
      <div class="lg:w-max mt-2">
        <h1 class="text-StemBlue font-semibold text-sm xl:text-base">Orë</h1>
        <div class="text-StemTextSecond text-sm xltext-base my-5">{{ data.time }}</div>
      </div>
      <div class="lg:w-max mt-2">
        <h1 class="text-StemBlue font-semibold text-sm xl:text-base">Çmimi</h1>
        <div class="text-StemTextSecond text-sm xl:text-base my-5">{{ data.price }}</div>
              
      </div>
    </div>
</div>
  
    
    
    <div class=" ">

      <div :class="buttonState ? 'block' : 'hidden'">
        <div class="  mx-5">
          <h1 class="text-StemBlue font-semibold text-xl my-5">{{ data.title }}</h1>
          <h1 class="text-StemTextSecond text-sm my-5 leading-loose   text-justify">{{data.description}}</h1>
          <h1 class=" text-left font-semibold text-StemBlue text-sm mt-5 my-2 leading-loose">{{data.text1}}</h1>
          <li class=" text-left text-StemTextSecond text-sm  leading-loose"
           v-for=" el in data.text2" :key="el">
            {{el}}
          </li>
          <div class="grid grid-cols-2 text-left py-10 mx-4">
            <h1 class="md:pl-5 text-left font-StemFont text-StemBlue text-sm border-2 border-StemBlue leading-loose">Fillimi</h1>
            <h2 class="md:pl-5 text-left  text-StemTextSecond text-sm border-2 border-StemBlue leading-loose">{{data.date}}</h2>
            <h1 class="md:pl-5 text-left font-StemFont text-StemBlue text-sm border-2 border-StemBlue leading-loose">Kohëzgjatja</h1>
            <h2 class="md:pl-5 text-left text-StemTextSecond text-sm border-2 border-StemBlue leading-loose">{{data.time}}</h2>
            <h1 class="md:pl-5 text-left font-StemFont text-StemBlue text-sm border-2 border-StemBlue leading-loose">Çmimi</h1>
            <h2 class="md:pl-5 text-left text-StemTextSecond text-sm border-2 border-StemBlue leading-loose">{{data.price}}</h2>
            <h1 class="md:pl-5 text-left font-StemFont text-StemBlue text-sm border-2 border-StemBlue leading-loose">Njohuri paraprake</h1>
            <h2 class="md:pl-5 text-left text-StemTextSecond text-sm border-2 border-StemBlue leading-loose">{{data.priorKnolage}}</h2>
            <h1 class="md:pl-5 text-left font-StemFont text-StemBlue text-sm border-2 border-StemBlue leading-loose">Përfitimet:</h1>
            <div class="border-2 border-StemBlue"><li v-for="el in data.benefits" :key="el" class="md:pl-5 text-left text-StemTextSecond text-sm  ">{{el}}</li></div>
            
          </div>
        </div>
      </div>
    </div>
    
  
</div>
<div class=" pb-4">
      <button v-on:click="changeButtonState()"
      class="rounded-lg
            bg-StemYellow
            hover:bg-transparent hover:text-StemYellow
            text-white
            border
            hover:border-StemYellow
            text-base
            font-normal
            w-24
            h-10"
      >
        <div v-if="buttonState">
          {{buttontext2}}
        </div>
      <div v-else>{{buttontext1}}</div>
      </button>
    </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data(){
    return{
      buttontext1:"Më shumë",
      buttontext2:"Më pak",
    }
  },
  methods: {
    ...mapActions(["changeButtonState"]),
  },
  computed: {
    ...mapState({
      buttonState: (state) => state.buttonModule.ideaLabShow,
    }),
  },
  props: ["data"],
};
</script>

<style scoped>

@media only screen and (min-width: 1025px) {
  .textwidthblla{
 
  width: 20%;
}

  
}
@media only screen and (max-width: 1024px) {
  
.textwidthblla{
 
  width: 20%;
}
}
  
  

@media only screen and (max-width: 640px) {
.textwidthblla{
 margin-left: 5px;
 margin-right: 5px;
  width: 100%;
}
  
}
</style>
<template>
  <div class="pt-48 md:px-24 px-3 AboutDeco1">
    <div class="AboutDeco2">
      <div class="grid md:grid-flow-col grid-flow-row">
        <div class="border-StemPurple border-l-2 pl-4">
          <h1 class="text-StemBlue font-bold text-4xl pr-4 text-left">
            IdeaLab
          </h1>
        </div>
      </div>
      <div class="pt-10 grid grid-flow-row">
        <p
          class="
            text-StemTextSecond
            font-normal
            text-sm
            pr-4
            leading-loose
            text-left
            w-8/12
          "
        >
          IdeaLab është njësi e veçantë e STEMLab, e cila ka për qëllim
          përfshirjen dhe trajnimin e stafit të shkollave, prindërve, studentëve
          bachelor dhe master në programet më të veçanta të edukimit që
          tangjentojnë me STEM edukimin.
      
          Aktivitetet do të organizohen në formate të ndryshme, edhe atë si
          masterclass, shkolla verore dhe dimërore, puntori dhe trajnime të
          çertifikuara.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style>
.AboutDeco1 {
  background-image: url("./../../assets/Svg/AboutDeco1.svg");
  background-repeat: no-repeat;
  background-position-x: 75%;
  background-position-y: 60%;
}
.AboutDeco2 {
  background-image: url("./../../assets/Svg/AboutDeco2.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}
</style>
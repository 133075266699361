<template>
  <div class="bg-white">
  <NavBarNew></NavBarNew>
  <IdealabHero></IdealabHero>
  <IdealabCards1></IdealabCards1>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import IdealabHero from "@/components/IdealabScreen/IdealabHero.vue";
import IdealabCards1 from "@/components/IdealabScreen/Idealab_Cards.vue";
import Footer from "@/components/HomeScreen/Footer.vue";
//import Header from '@/components/HomeScreen/header.vue'

export default {
  name: "Idealab",
  components: {
    NavBarNew,
    IdealabHero,
    IdealabCards1,
    Footer,
  },
};
</script>